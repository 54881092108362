@import 'src/styles/helpers/mixins';

.service-cards {
  --n: 2;
  display: grid;
  grid-template-columns: repeat(var(--n), 1fr);
  gap: $gutter;

  @media (max-width: $screen-1024) {
    --n: 1;
  }

  @include breakpoint(mobile) {
    gap: 24px;
  }
}

.additional-service-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 290px;
  padding: 36px 100px 36px 36px;
  border-radius: 20px;
  background: #fff;

  @include breakpoint(mobile) {
    padding: 36px 36px 36px 36px;
  }

  &__body {
    flex: 1;
  }

  &__img {
    display: block;
    max-width: 100%;
    margin-top: 16px;
  }

  &__button {
    position: absolute;
    z-index: 5;
    top: 26px;
    right: 26px;

    &_blocked {
      background: $concrete-grey;
      color: $premium-black;
      pointer-events: none;
      font-size: 24px;
    }
  }

  &__title {
    margin: 0 0 12px 0;
  }

  &__text {
    margin: 16px 0 0;
  }

  &__footer {
    margin-top: 16px;
  }

  &__link {
    color: $amplify-lilac;

    &:hover {
      text-decoration: none;
    }
  }
}
