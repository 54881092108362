#onetrust-accept-btn-handler,
#onetrust-reject-all-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler {
  background-color: #1470CC !important;
  border-color: #1470CC !important;
  border-radius: 100px !important;
  padding: 14px !important;
}

#onetrust-pc-btn-handler {
  color: #1470CC !important;
  border-color: #1470CC !important;
  border-radius: 100px !important;
  padding: 14px !important;
}

.privacy-notice-link,
.ot-always-active {
  color: #1470CC !important;
}

#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob {
  background-color: #1470CC !important;
  border: 1px solid #1470CC !important;
}

#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob:before {
  background-color: #FFFFFF !important;
}
