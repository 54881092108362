.step-cards {
  counter-reset: card;

  .slider__track {
    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }
}

.step-card {
  flex: none;
  display: flex;
  flex-direction: column;
  min-height: 560px;
  width: 412px;
  background: $bright-white;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  counter-increment: card;
  overflow: hidden;

  @include breakpoint(mobile) {
    min-height: 435px;
    width: auto;
  }
  
  &__show-counter {
    position: relative;
    
    &:before {
      @include icon(60px);
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -50%);
      content: counter(card);
      margin-top: 5px;
      background: $bright-white;
      border-radius: 50%;
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.04);
      font-size: 17px;
      line-height: 1;
    } 
  }

  &__image-container {
    max-height: 200px;
    overflow: hidden;
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__body {
    flex: 1;
    display: grid;
    grid-auto-rows: 1fr auto;
    gap: $gutter;
    padding: 56px 36px 36px 36px;
    text-align: center;
  }

  &__title {
    margin: 0 0 10px;
  }

  &__text {
    color: $premium-black-60;
  }
}
