// General Project Styles
@import 'common/normalize';
@import 'helpers/variables';
@import 'helpers/mixins';
@import 'common/fonts';
@import 'common/forms';
@import 'common/typography';
@import 'common/buttons';
@import 'common/one-trust';

*,
*:after,
*:before {
  box-sizing: inherit;
}

html {
  @include font;
  color: $premium-black;
  box-sizing: border-box;
}

.visually-hidden {
  position: fixed;
  transform: scale(0);
}

.icon {
  height: 1em;
  width: 1em;
}

.logo {
  &__img {
    display: block;
    max-width: 100%;
  }
}

.shell {
  max-width: 1440px;
  margin: auto;
  width: 100%;

  @include shell-padding();

  .shell {
    padding: 0;
  }
}

.theme-yellow {
  background: $sand-beige;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

body.disable-global-scroll {
  overflow: hidden;
}

.animated-height {
  transition: height 225ms cubic-bezier(1,1,0,0);
}

input::placeholder {
  overflow: visible;
}
