.headline-0 {
  @include h0();

  @include breakpoint(tablet) {
    @include h0(mobile);
  }
}

h1,
.headline-1 {
  @include h1();

  @include breakpoint(tablet) {
    @include h1(mobile);
  }
}

h2,
.headline-2 {
  @include h2();

  @include breakpoint(tablet) {
    @include h2(mobile);
  }
}

h3,
.headline-3 {
  @include h3();

  @include breakpoint(tablet) {
    @include h3(mobile);
  }
}

h4,
.headline-4 {
  @include h4();

  @include breakpoint(tablet) {
    @include h4(mobile);
  }
}

h5,
.headline-5 {
  @include h5();

  @include breakpoint(tablet) {
    @include h5(mobile);
  }
}

h6,
.headline-6 {
  @include h6();

  @include breakpoint(tablet) {
    @include h6(mobile);
  }
}

a,
a:visited {
  color: $amplify-lilac;
}

.text-large {
  @include text-large();

  @include breakpoint(tablet) {
    @include text-large(mobile);
  }
}

.text-normal {
  @include text-normal();

  @include breakpoint(tablet) {
    @include text-normal(mobile);
  }
}

.text-small {
  @include text-small();

  @include breakpoint(tablet) {
    @include text-small(mobile);
  }
}

.text-tiny {
  @include text-tiny();

  @include breakpoint(tablet) {
    @include text-tiny(mobile);
  }
}

@for $i from 0 through 6 {
  .h#{$i} {
    @include headline($i);
  }
}

@include breakpoint(mobile) {
  @for $i from 0 through 6 {
    .m-h#{$i} {
      @include headline($i, mobile);
    }
  }
}

.t-large {
  @include text-large();
}

.t-normal {
  @include text-normal();
}

.t-small {
  @include text-small();
}

.t-tiny {
  @include text-tiny();
}

.t-btn-normal {
  @include font(16px, 24px, 500);
}

@include breakpoint(mobile) {
  .m-t-large {
    @include text-large(mobile);
  }

  .m-t-normal {
    @include text-normal(mobile);
  }

  .m-t-small {
    @include text-small(mobile);
  }

  .m-t-tiny {
    @include text-tiny(mobile);
  }

  .m-t-btn-normal {
    @include font(13px, 13px, 400);
  }
}

.weight-normal {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: 700;
}

.zero-margin {
  margin: 0;
}