.aem {
  &-top {
    &-none {
      display: block;
      padding-top: 0;
    }

    &-small {
      display: block;
      padding-top: 18px;
    }

    &-medium {
      display: block;
      padding-top: 36px;
    }

    &-high {
      display: block;
      padding-top: 54px;
    }
  }

  &-bottom {
    &-none {
      display: block;
      padding-bottom: 0;
    }

    &-small {
      display: block;
      padding-bottom: 18px;
    }

    &-medium {
      display: block;
      padding-bottom: 36px;
    }

    &-high {
      display: block;
      padding-bottom: 54px;
    }
  }
}

