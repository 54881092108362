.tooltip-container {
  position: fixed;
  z-index: 100;
  opacity: 0;
  animation: tooltip-slide 0.1s ease-out 0.3s;
  animation-fill-mode: forwards;
  pointer-events: none;
  padding: 16px;
  background-color: $amplify-lilac;
  color: $stone-grey;
  border-radius: 4px;
  max-width: 280px;
  width: max-content;
  word-break: break-word;

  &::after {
    content: " ";
    width: 5px;
    height: 14px;
    background-image: url('/assets/img/tooltip-arrow.svg');
    display: block;
    position: absolute;
  }

  &_left::after {
    top: calc(50% - 5px);
    right: -4px;
  }

  &_right::after {
    top: calc(50% - 5px);
    left: -4px;
    transform: rotate(180deg);
  }

  &_top::after {
    bottom: -9px;
    left: calc(50% - 5px);
    transform: rotate(90deg);
  }

  &_bottom::after {
    top: -9px;
    left: calc(50% - 5px);
    transform: rotate(-90deg);
  }
}

.tooltip-font-normal {
  @include font(16px, 24px);
}

.tooltip-font-small {
  @include font(14px, 22px);
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
