@font-face {
  font-family: 'Core Sans C';
  font-weight: normal;
  font-style: normal;
  src: local('Core Sans C 45 Regular'), local('CoreSansC-45Regular'),
  url('/assets/fonts/CoreSansC-45Regular.woff2') format('woff2'),
  url('/assets/fonts/CoreSansC-45Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Core Sans C';
  font-weight: 500;
  font-style: normal;
  src: local('Core Sans C 55 Medium'), local('CoreSansC-55Medium'),
  url('/assets/fonts/CoreSansC-55Medium.woff2') format('woff2'),
  url('/assets/fonts/CoreSansC-55Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Core Sans C';
  font-weight: bold;
  font-style: normal;
  src: local('Core Sans C 65 Bold'), local('CoreSansC-65Bold'),
  url('/assets/fonts/CoreSansC-65Bold.woff2') format('woff2'),
  url('/assets/fonts/CoreSansC-65Bold.woff') format('woff');
  font-display: swap;
}
