// Colors
// Primary Colors
$black: #010101;
$premium-black: #151515;
$light-black: #373F41;
$amplify-lilac: #5A26CE;
$electric-yellow: #EEFF3C;
$peace-of-mind-green: #0F7239;
$green: #5BA25E;

$concrete-grey: #E6E6E6;
$grey: #737373;
$stone-grey: #FAFAFA;
$bright-white: #fff;
$sand-beige: #F9F5EC;
$denim-blue: #0B307E;

// Secondary Colors
$home: #EECEDA;
$home-02: #E8A5BE;
$home-03: #B55C7E;
$mobility: #EFCE9C;
$mobility-02: #E1B790;

$health: #D6C5FD;
$health-02: #B69EED;
$health-03: #7557BA;
$travel: #BCDFFF;
$travel-02: #8EC4F7;
$trip: #EECEDA;
$trip-02: #dd6391;
$flight-01: #B0E9EC;
$flight-03: #15949C;
$default-header: #1470CC;
$quote-color: #0F7239;
$box-shadow: rgba(0, 0, 0, 0.04);
$dividing-line: rgba(21, 21, 21, 0.1);
$disabled-text: rgba(21, 21, 21, 0.6);
$insurance: #597BC4;
$insurance-01: #597BC3;
$insurance-02: #223F7D;
$card-border: #947B60;
$placeholder: #909090;
$accordion-border: #959595;


// Tertiary Colors
$peace-of-mind-green-10: rgba(15, 114, 57, 0.1);
$premium-black-03: rgba(21, 21, 21, 0.03);
$premium-black-10: rgba(21, 21, 21, 0.1);
$premium-black-30: rgba(21, 21, 21, 0.3);
$premium-black-40: rgba(21, 21, 21, 0.4);
$premium-black-60: rgba(21, 21, 21, 0.6);
$bright-white-30: rgba(255, 255, 255, 0.6);
$platinum-silver: #CECECE;

$notification-red: #F77778;
$label-red: #EE6667;
$error-block: #FFD0CE;

// buttons
$button-height: 64px;
$button-height-md: 54px;
$button-height-sm: 48px;
$button-radius: $button-height*0.5;
$button-color: $premium-black;
$button-shadow-color: rgba(65, 65, 65, 0.25);
$button-color-hover: lighten($premium-black, 10%);
$icon-button-size: 60px;
$pagination-button-color: #D9D9D9;

// fields

$field-border-color: $concrete-grey;
$field-border-color-hover: rgba(21, 21, 21, 0.6);
$field-border-color-active: rgba(0, 116, 196, 0.5);
$field-shadow-color-active: #0074C4;
$field-error-color: #E50002;
$field-placeholder-color: rgba(21, 21, 21, 0.6);
$field-border-period:#947b60;

// progress bar

$progress-bar-weak: #FB000F;
$progress-bar-fine: #FBC400;
$progress-bar-good: $peace-of-mind-green;
$progress-bar-strong: $amplify-lilac;

// slider
$slider-control-hover: #848484;

// Fonts
$font-size-base: 18px;
$line-height-base: 28px;
$font-family-base: 'Core Sans C', Helvetica, Arial, sans-serif;

// Sizes
$gutter: 20px;

// -------- Animations -----------
$duration-base: 0.15s;
$timing-function-base: ease-out;

// Breakpoints
$screen-xxs: 375px;
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1150px;
$screen-xl: 1200px;

$screen-1440: 1440px;
$screen-1100: 1100px;
$screen-1024: 1024px;
$screen-640: 640px;
$screen-860: 860px;

// Pagination

$pagination-theme-purple: (
  background: $bright-white,
  background-disabled: $pagination-button-color,
  button-color: $black,
  link-border: $amplify-lilac
);

$pagination-theme-grey: (
  background: $pagination-button-color,
  background-disabled: $pagination-button-color,
  button-color: $black,
  active-button-color: $amplify-lilac,
  active-button-background: $bright-white,
  link-border: $pagination-button-color
);
