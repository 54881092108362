@import './variables';

@mixin font(
  $font-size: $font-size-base,
  $line-height: $line-height-base,
  $font-weight: 400,
  $font-family: $font-family-base,
  $font-style: normal
) {
  font: $font-weight $font-style #{$font-size}/#{$line-height} $font-family;
}

@mixin transition($property: all, $duration: $duration-base, $timing-function: $timing-function-base, $delay: 0) {
  transition: $property $duration $timing-function;
}

@mixin placeholder($color: $placeholder-color) {
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      opacity: 1;
      color: $color;
      @include transition;
    }
    &:focus:#{$placeholder}-placeholder {
      opacity: 0;
      text-indent: -50px;
    }
  }
}

@mixin popups {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  max-height: 600px;
  height: calc(100% - 50px);
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
}

@mixin alignmentY($offsetY: 0) {
  position: absolute;
  left: 50%;
  top: calc(50% + #{$offsetY});
  transform: translate(-50%, -50%);
}

@mixin abs-centering() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex-centering($display: 'flex') {
  @if $display == 'flex' {
    display: flex;
  }

  @if $display == 'inline-flex' {
    display: inline-flex;
  }

  justify-content: center;
  align-items: center;
}

@mixin overlay() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin text-overflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin custom-scroll() {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;

    border-radius: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    //background: $gray-1;
  }
}

@mixin icon($size) {
  @include flex-centering;
  width: $size;
  height: $size;
}

@mixin shell-padding() {
  padding: 0 80px;

  @include breakpoint(tablet) {
    padding: 0 40px;
  }

  @include breakpoint(mobile) {
    padding: 0 15px;
  }
}

@mixin form() {
  background-color: $bright-white;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 32px;
  border-radius: 20px;
  height: fit-content;
  margin-top: auto;

  @media (max-width: $screen-xl) {
    padding: 20px;
  }
}

@mixin breakpoint($breakOn) {
  @if $breakOn == desktop-narrow {
    @media (max-width: $screen-lg) { @content; }
  }
  @if $breakOn == tablet {
    @media (max-width: $screen-md) { @content; }
  }
  @if $breakOn == mobile {
    @media (max-width: $screen-xs) { @content; }
  }
}

// Typography
@mixin h0($type: desktop) {
  @if $type == mobile {
    @include font(30px, 34px, 500);
    letter-spacing: normal;
  } @else {
    @include font(78px, 86px, 500);
    letter-spacing: -0.02em;
  }
}

@mixin h1($type: desktop) {
  @if $type == mobile {
    @include font(30px, 34px, 500);
    letter-spacing: normal;
  } @else {
    @include font(68px, 72px, 500);
    letter-spacing: -0.02em;
  }
}

@mixin h2($type: desktop) {
  @if $type == mobile {
    @include font(26px, 30px, 500);
    letter-spacing: normal;
  } @else {
    @include font(54px, 68px, 500);
    letter-spacing: -0.01em;
  }
}

@mixin h3($type: desktop) {
  @if $type == mobile {
    @include font(18px, 24px, 500);
    letter-spacing: normal;
  } @else {
    @include font(48px, 54px, 500);
    letter-spacing: -0.01em;
  }
}

@mixin h4($type: desktop) {
  @if $type == mobile {
    @include font(16px, 24px, 500);
    letter-spacing: normal;
  } @else {
    @include font(42px, 51px, 500);
  }
}

@mixin h5($type: desktop) {
  @if $type == mobile {
    @include font(13px, 26px, 500);
    letter-spacing: normal;
  } @else {
    @include font(28px, 33px, 500);
  }
}

@mixin h6($type: desktop) {
  @if $type == mobile {
    @include font(11px, 14px, 600);
    letter-spacing: normal;
  } @else {
    @include font(18px, 24px, 600);
  }
}

@mixin text-large($type: desktop) {
  @if $type == mobile {
    @include font(16px, 22px, normal);
  } @else {
    @include font(19px, 28px, normal);
  }
}

@mixin text-normal($type: desktop) {
  @if $type == mobile {
    @include font(14px, 22px, normal);
  } @else {
    @include font(18px, 28px, normal);
  }
}


@mixin text-small($type: desktop) {
  @if $type == mobile {
    @include font(13px, 17px, normal);
  } @else {
    @include font(16px, 24px, normal);
  }
}

@mixin text-tiny($type: desktop) {
  @if $type == mobile {
    @include font(12px, 15px, normal);
  } @else {
    @include font(14px, 22px, normal);
  }
}

@mixin headline($size, $type: desktop) {
  @if $size == 0 {
    @include h0($type);
  } @else if $size == 1 {
    @include h1($type);
  } @else if $size == 2 {
    @include h2($type);
  } @else if $size == 3 {
    @include h3($type);
  } @else if $size == 4 {
    @include h4($type);
  } @else if $size == 5 {
    @include h5($type);
  } @else if $size == 6 {
    @include h6($type);
  }
}

@mixin pagination-theme($theme) {
  ::ng-deep .ngx-pagination {
    padding-left: 0;

    li {
      background-color: map-get($theme, background);
      border-radius: 4px;
      margin-right: 3px;
    }

    .pagination-next,
    .pagination-previous {
      margin: 0 16px;
      width: 150px;

      @include breakpoint(mobile) {
        margin: 0 4px;
        width: 40px;
      }
    }

    .pagination-next:after {
      @include breakpoint(mobile) {
        margin-left: 0;
      }
    }

    .pagination-next a:after {
      @include breakpoint(mobile) {
        margin-left: 0;
      }
    }

    .disabled {
      color: map-get($theme, button-color);
      opacity: 50%;
      text-align: center;
      border: 1px solid map-get($theme, background-disabled);
      background: map-get($theme, background-disabled);
    }

    a {
      text-align: center;
      background: map-get($theme, background);
      color: map-get($theme, button-color);
      border: 1px solid map-get($theme, link-border);
      border-radius: 4px;
    }

    .current {
      @if $theme == $pagination-theme-purple {
        color: map-get($theme, background);
        background: map-get($theme, link-border);
        border: 1px solid map-get($theme, link-border);
      }
      @else if $theme == $pagination-theme-grey {
        color: map-get($theme, active-button-color);
        background: map-get($theme, active-button-background);
        border: 1px solid map-get($theme, active-button-color);
      }
    }

    .ellipsis {
      background-color: inherit;

      a {
        border: none;
        background-color: inherit;
      }
    }
  }
}
