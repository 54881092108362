.section {
  $this: &;

  position: relative;
  margin: 100px 0;
  border-radius: 60px / 12px;

  @include breakpoint(mobile) {
    margin: 60px 0;
    border-radius: 0;
  }

  &_without-border {
    border-radius: 0;
  }

  &_colored {
    padding: 72px 0;
    background: $sand-beige;
  }

  &_pb-0 {
    padding-bottom: 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 520px;
    gap: 10%;
    align-items: start;

    @media (max-width: $screen-xl) {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: center;
    }
  }

  &__illustration {
    @media (max-width: $screen-xl) {
      width: 260px;
      order: -1;
    }
  }

  &__label {
    font-size: 19px;

    @media (max-width: $screen-1440) {
      margin-top: 12px;
    }
  }

  &__header {
    margin-bottom: 48px;

    &_centered {
      text-align: center;

      #{$this}__label {
        max-width: 500px;
        margin: auto;
        padding: 12px 0 16px 0;
      }
    }

    @include breakpoint(tablet) {
      &_mobile-centered {
        text-align: center;
      }
    }
  }

  &__hgrid {
    display: grid;
    grid-template-columns: 610px 1fr;
    gap: $gutter;
    align-items: end;

    @media (max-width: $screen-1440) {
      display: block;
    }
  }

  &__hgroup {
    padding-bottom: 5px;
  }

  &__column {
    align-self: center;

    &_top {
      align-self: flex-start;
    }

    @media (max-width: $screen-xl) {
      text-align: center;
    }

    &_content-right {
      margin-left: auto;

      @media (max-width: $screen-xl) {
        margin-left: inherit;
      }
    }
  }

  &__title {
    margin: 0;

    @media (max-width: $screen-xl) {
      @include font(26px, 30px, 500);
    }
  }

  &__text {
    margin: 0;

    & + & {
      margin-top: 16px;
    }
  }

  &__button {
    margin-top: 48px;

    @include breakpoint(tablet) {
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__button + &__text {
    margin-top: 48px;
  }

  &_centered {
    text-align: center;
  }
}

.travels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 352px;
}
