@import "../helpers/mixins";
@import "../helpers/variables";

.button {
  $this: &;

  @include transition;
  @include font(17px, 1, 500);
  @include flex-centering("inline-flex");
  flex: none;
  column-gap: 8px;
  height: $button-height;
  padding: 5px 32px;
  border-radius: $button-radius;
  border: 1px solid $button-color;
  background-color: $button-color;
  color: $stone-grey;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: $button-color-hover;
    border-color: $button-color-hover;
  }

  &:active {
    outline: none;
  }

  &:active,
  &:focus-visible {
    outline: 4px solid $health-02;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  &__icon {
    font-size: 24px;
    color: $electric-yellow;

    #{$this}_lighten & {
      color: $amplify-lilac;
    }
  }

  &_md {
    @include font(15px, 1, 500);
    height: 54px;
  }

  &_sm {
    @include font(15px, 1, 500);
    height: 48px;
    padding-left: 30px;
    padding-right: 30px;

    #{$this}__icon {
      font-size: 20px;
      margin-right: 4px;
    }
  }

  &_big-width {
    max-width: 412px;
    width: 100%;
  }
}

.slider-dots {
  $size: 20px;

  display: flex;
  gap: 28px;

  &__dot {
    width: $size;
    height: $size;
    border: 1px solid $amplify-lilac;
    background: transparent;
    border-radius: 50%;
    padding: 0;

    &_active,
    &_active:hover {
      background: $amplify-lilac;
    }
  }
}

.button {
  &_lighten {
    background-color: transparent;
    border: 1px solid $premium-black-60;
    color: $premium-black;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
      border: 1px solid $premium-black-60;
    }

    &:hover {
      opacity: 0.8;
      background-color: $stone-grey;
    }

    &:active,
    &:focus-visible {
      background: transparent;
      border-color: transparent;
      outline: 4px solid $health-02;
    }

    &[disabled] {
      pointer-events: none;
    }
  }
}

.link-button {
  background: none;
  border: none;
  padding: 5px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: $button-height;

  @include font(17px, 1, 500);
}
