.footer {
  padding: 70px 0;
  background: rgba(21, 21, 21, 0.03);

  @media (max-width: $screen-xl) {
    padding: 18px 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: 126px 1fr 115px;
    gap: 7%;
    align-items: start;

    @media (max-width: $screen-xl) {
      display: block;
    }
  }

  &__logo {
    margin-top: 20px;
  }

  &__logo-img {
    display: block;
    max-width: 100%;
  }

  &__copyright {
    margin-top: 20px;
  }
}

.footer-nav {
  --n: 2;
  --gap: 30px;

  display: grid;
  grid-template-columns: minmax(min-content, 296px) 1fr;
  gap: var(--gap);

  @media (max-width: $screen-xl) {
    margin-top: 18px;
    display: block;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__link {
    margin: 8px 0;
    font-size: 16px;
    line-height: 24px;
    color: $premium-black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  line-height: 24px;
  text-align: right;

  &__img {
    display: block;
  }

  &__text {
    margin-top: 12px;
  }
}
