@import '../helpers/variables';

.form-field {
  height: 56px;
  width: 100%;
  background: $bright-white;
  border: 1px solid $field-border-color;
  border-radius: 16px;
  overflow: visible;
  padding: 0 16px 0 24px;

  &:hover {
    border-color: $field-border-color-hover;
  }

  &.form-field_invalid {
    border-color: $field-error-color;
  }

  &.form-field_focused {
    border-color: transparent;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      width: 100%;
      height: 100%;
      border: 4px solid $health;
      border-radius: 16px;
      box-sizing: content-box;
      z-index: -1;
    }
  }

  &.form-field_disabled {
    border-color: $concrete-grey;
    opacity: 0.6;
    pointer-events: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.ng-invalid.ng-touched > .form-field:not(.form-field_focused),
.ng-invalid.ng-touched.form-field:not(.form-field_focused) {
  border-color: $field-error-color;
}
