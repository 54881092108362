.header {
  $this: &;

  &__main {
    padding: 0;
    background: var(--header-primary);
  }

  &__toolbar {
    display: grid;
    grid-template-columns: 134px 1fr min-content;
    gap: 20px;
    align-items: center;

    @media (max-width: $screen-1440) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__nav {
    justify-self: center;
  }

  &__brief {
    margin-top: 26px;
  }

  &__img {
    $height: 590px;

    display: block;
    max-height: $height;
    max-width: 100%;
    margin: auto;
    border-radius: $height*0.5;
  }

  &__bottom {
    background: linear-gradient(180deg, var(--header-primary) 44%, transparent 0% );
  }

  &__breadcrumbs {
    margin: 0;
  }

  &__intro {
    margin-top: 36px;
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 40px;

  @include breakpoint(tablet) {
    display: none;
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
    color: $premium-black;
    text-decoration: none;

    &_active {
      @include flex-centering;
      height: 48px;
      padding: 0 30px;
      border-radius: 24px;
      text-decoration: none;
      background: var(--header-secondary-active-background);
      font-size: 15px;
      color: var(--header-secondary-active);
    }

    &:not(.nav__link_active):hover {
      text-decoration: underline;
    }
  }
}

.brief {
  display: grid;
  grid-template-columns: 1fr 518px;
  gap: 10%;
  padding: 72px 0 36px 0;

  @media (max-width: $screen-1440) {
    display: block;
    padding-top: 56px;
  }

  &__title {
    margin: 0;
  }

  &__group {
    padding-top: 10px;

    @media (max-width: $screen-1440) {
      margin-top: 2px;
    }
  }

  &__text {
    margin: 0;
  }

  &__button {
    margin-top: 52px;

    @media (max-width: $screen-xl) {
      margin-top: 30px;
    }
  }
}
